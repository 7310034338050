import * as XLSX from "xlsx";
import Section, { ISection } from "./Section";
import {
  getChapterProperties,
  getColumnCountFromCsv,
  transformSheetPrices,
} from "../assets/utils";
import { ECOMPANY } from "../constants";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../App";
import { CHAPTER_HEIGHT } from "../PdfPreview";
import TotalSection from "./TotalSection";
import { isMobile } from "react-device-detect";

export interface IChapter {
  title?: string;
  name: string;
  sections: ISection[];
  _sections?: ISection[];
  group?: number;
  dataSheet?: XLSX.WorkSheet;
  breakSections?: boolean;
  noSections?: boolean;
  hideInCuanto?: boolean;
  total?: Record<string, number>;
  totalOfTotals?: number;
  priority?: number;
  averages?: Record<string, { manual: number; auto: number }>;
  bars?: Record<string, { manual: number; auto: number }>;
}

const Chapter = (chapter: IChapter) => {
  //const ref  = useRef<HTMLDivElement>(null);
  // @ts-ignore

  const [company] = useContext(Context);
  const [sectionsBroke, setSectionsBroke] = useState(false);
  const chapterRef = useRef(null);
  const secondPageRef = useRef(null);
  const chapterProperties = getChapterProperties(chapter?.name);
  const tableColumnCount =
    chapter.dataSheet &&
    getColumnCountFromCsv(XLSX.utils.sheet_to_csv(chapter.dataSheet, {}));
  useEffect(() => {
    if (chapterRef.current && chapter.breakSections) {
      const imgHeight =
        (chapterRef.current as HTMLDivElement).querySelector(".chapter-image")
          ?.clientHeight || 0;
      if (
        (chapterRef.current as HTMLDivElement).clientHeight - imgHeight >
        CHAPTER_HEIGHT
      ) {
        setSectionsBroke(true);
        // get all sections
        const sections = (
          chapterRef.current as HTMLDivElement
        ).querySelectorAll(".section");
        // split the sections in half and take the second half
        const secondHalf = Array.from(sections).slice(
          Math.ceil(sections.length / 2) + 1
        );
        // remove the sections from the first page
        secondHalf.forEach((section) => {
          (chapterRef.current as unknown as HTMLDivElement).removeChild(
            section
          );
        });
        // add the sections to the second page
        if (secondPageRef.current) {
          // move the image from first page to second page
          secondHalf.forEach((section) => {
            (secondPageRef.current as unknown as HTMLDivElement).appendChild(
              section
            );
          });
          const image = (chapterRef.current as HTMLDivElement).querySelector(
            ".chapter-image"
          );
          if (image) {
            (secondPageRef.current as unknown as HTMLDivElement).appendChild(
              image
            );
          }
        }
      }
    }
  }, [chapter.breakSections, secondPageRef, chapterRef]);

  return (
    <>
      <div
        key={chapter.name}
        data-group={chapter?.group || 0}
        data-name={chapter?.name?.trim()}
        data-priority={chapter?.priority || 0}
        className={`chapter ${
          chapterProperties?.notFullPage ? "notFullPage" : ""
        }`}
        ref={chapterRef}
      >
        <img
          className="logo main"
          src={
            process.env.PUBLIC_URL +
            `/icons/${company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"}.svg`
          }
          alt=""
        />
        <h2 className={"chapter-title"} contentEditable={!isMobile}>
          {" "}
          {chapterProperties && chapterProperties.icon && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties.icon} />
          )}
          <span>
            {chapterProperties?.altName
              ? chapterProperties.altName
              : chapter.name}
          </span>
        </h2>
        {chapter.sections
          .filter((section) => section.name !== "כותרת על")
          .map((section) => Section(section))}
        {chapter.dataSheet && (
          <div
            className={`no-page-break ${
              (chapterProperties && chapterProperties.tableClass) || "table1"
            } table-col-count-${tableColumnCount}`}
            dangerouslySetInnerHTML={{
              __html: transformSheetPrices(
                XLSX.utils.sheet_to_html(chapter.dataSheet, { editable: true }),
                { removeDecimals: chapterProperties?.noDecimals }
              ),
            }}
          />
        )}
        <div className="before-total-section hidden"></div>
        {chapter.total && chapter.totalOfTotals ? (
          <TotalSection
            name={chapter.name}
            total={chapter.total}
            totalOfTotals={chapter.totalOfTotals}
            averages={chapter.averages}
            bars={chapter.bars}
          />
        ) : null}
        <div className="chapter-end-filler no-page-break chapter-image">
          {chapterProperties && chapterProperties.img && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties?.img} />
          )}
        </div>
        <div className="footer">
          <img
            src={
              process.env.PUBLIC_URL +
              `/images/${
                company === ECOMPANY.CUANTO ? "footer_cuanto" : "footer"
              }.jpg`
            }
            alt=""
          />
        </div>
      </div>
      <div
        key={chapter.name}
        data-name={chapter?.name?.trim()}
        className={`chapter ${
          chapterProperties?.notFullPage ? "notFullPage" : ""
        } ${isMobile ? "mobile" : ""}`}
        ref={secondPageRef}
        style={{
          display: sectionsBroke ? "flex" : "none",
        }}
      >
        <img
          className="logo main"
          src={
            process.env.PUBLIC_URL +
            `/icons/${company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"}.svg`
          }
          alt=""
        />
        <div
          style={{
            marginTop: "3em",
          }}
        ></div>
      </div>
    </>
  );
};

export default Chapter;
