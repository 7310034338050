import * as XLSX from "xlsx";
import { groupBy } from "lodash/fp";
import Chapter, { IChapter } from "./pdf_components/Chapter";
import { createElement, useContext, useEffect, useRef, useState } from "react";
import { ISection } from "./pdf_components/Section";
import {
  getChapterProperties,
  handleBasementComments,
  mergeAndTrimChapter,
  modifyTdElPaddingX,
} from "./assets/utils";
import { Context } from "./App";
import { ECOMPANY, ECUANTO_TABLE_COLORS, firebaseConfig } from "./constants";
import { groupImages } from "./assets/images_mapping";
import Drawer from "@mui/material/Drawer";
import {
  ListItemButton,
  ListItemText,
  List,
  ButtonGroup,
  Button,
  Stack,
  Box,
} from "@mui/material";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import RegisterNewProjectModal from "./RegisterNewProjectModal";
const INDEX_SHEET = "Index for PDF";
const CUANTO_SHEET = "ייצוא קואנטו";
const CHAPTER_COLUMN = "פרק";
const SECTION_COLUMN = "תת-פרק";
const ENTRIES_COLUMN = "סעיף";
const DATA_TABLE_ENTRY_PREFIX = "טבלת";
const TOTAL_KEYWORDS = ["הכ", 'סה"כ'];
const AVERAGE_KEYWORDS = ["ממוצע"];
export const CHAPTER_HEIGHT = 1150;
export const OVERFLOW_BUFFER = 200;

// Initialize Firebase// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);

const shouldSkipSection = (sectionRaw: any) => {
  if (sectionRaw[0][ENTRIES_COLUMN]?.includes("ממוצע")) {
    return true;
  }

  if (
    sectionRaw[0][SECTION_COLUMN] === "0" &&
    sectionRaw[0][ENTRIES_COLUMN].includes("(פרק)")
  ) {
    return true;
  }
};

const isEntryADataTablePlaceholder = (chapterName: string, sectionRaw: any) => {
  return sectionRaw[0][ENTRIES_COLUMN].startsWith(DATA_TABLE_ENTRY_PREFIX);
};

const getDataSheetForTable = (
  chapterName: string,
  workbook: XLSX.WorkBook,
  dataTableRow: any
) => {
  const inferedSheetNameFromEntryText = dataTableRow[ENTRIES_COLUMN].split(
    DATA_TABLE_ENTRY_PREFIX
  )[1].trim();

  return workbook.Sheets[inferedSheetNameFromEntryText];
};

// Custom hook to run an effect a specific number of times
function useRunEffect(effect: () => void, dependencies: any[], times: number) {
  const runCountRef = useRef(0);

  useEffect(() => {
    if (runCountRef.current < times) {
      effect();
      runCountRef.current += 1;
    }
    document.querySelectorAll(".section, .chapter-image").forEach((el) => {
      const section = el as HTMLElement;

      section.contentEditable = "true";

      // Initialize margin if not set
      section.style.marginTop = section.style.marginTop || "0px";

      // Create main container
      const mainContainer = document.createElement("div");
      mainContainer.style.cssText =
        "position: absolute; right: -60px; display: flex; gap: 5px;";
      mainContainer.classList.add("hide-on-print");

      // Create arrow container
      const arrowContainer = document.createElement("div");
      arrowContainer.style.cssText =
        "display: flex; flex-direction: column; gap: 5px;";

      // Create utility container
      const utilityContainer = document.createElement("div");
      utilityContainer.style.cssText =
        "display: flex; flex-direction: column; gap: 5px;";

      // Create up arrow
      const upArrow = document.createElement("button");
      upArrow.innerHTML = "↑";
      upArrow.style.cssText = "cursor: pointer; width: 24px; height: 24px;";
      upArrow.onclick = () => {
        const currentMargin = parseInt(section.style.marginTop) || 0;
        section.style.marginTop = `${currentMargin + 10}px`;
      };

      // Create down arrow
      const downArrow = document.createElement("button");
      downArrow.innerHTML = "↓";
      downArrow.style.cssText = "cursor: pointer; width: 24px; height: 24px;";
      downArrow.onclick = () => {
        const currentMargin = parseInt(section.style.marginBottom) || 0;
        section.style.marginBottom = `${Math.max(0, currentMargin + 10)}px`;
      };

      // Create reset button
      const resetButton = document.createElement("button");
      resetButton.innerHTML = "↺";
      resetButton.style.cssText = "cursor: pointer; width: 24px; height: 24px;";
      resetButton.onclick = () => {
        section.style.marginTop = "0px";
        section.style.marginBottom = "0px";
      };

      // Create remove button
      const removeButton = document.createElement("button");
      removeButton.innerHTML = "✕";
      removeButton.style.cssText =
        "cursor: pointer; width: 24px; height: 24px;";
      removeButton.onclick = () => {
        section.remove();
      };

      // Add arrows to arrow container
      arrowContainer.appendChild(upArrow);
      arrowContainer.appendChild(downArrow);

      // Add utility buttons to utility container
      utilityContainer.appendChild(resetButton);
      utilityContainer.appendChild(removeButton);

      // Combine containers
      mainContainer.appendChild(arrowContainer);
      mainContainer.appendChild(utilityContainer);

      // Add main container next to section
      section.style.position = "relative";
      section.appendChild(mainContainer);
    });
  }, dependencies);
}

const PdfPreview = (workbook: XLSX.WorkBook) => {
  const [company] = useContext(Context);
  const isCuanto = company === ECOMPANY.CUANTO;
  const pdfRef = useRef<HTMLDivElement>(null);
  const [showRegisterNewProjectModal, setShowRegisterNewProjectModal] =
    useState(false);

  const indexSheet = workbook.Sheets[INDEX_SHEET];
  const cuantoSheet = workbook.Sheets[CUANTO_SHEET];

  const averagesSheet = workbook?.Sheets?.["גיליון ממוצעים"];
  const [isLoading, setIsLoading] = useState(true);
  const [viewType, setViewType] = useState<"print" | "web">("print");

  const index = XLSX.utils.sheet_to_json(indexSheet);
  const cuanto = XLSX.utils.sheet_to_json(cuantoSheet);
  const averages = XLSX.utils.sheet_to_json(averagesSheet);

  // Grouping function

  function groupData(data) {
    return data.reduce((acc, row) => {
      const key = row[0] || "Other"; // Set "Other" for any row where the first column is undefined
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(row);
      return acc;
    }, {});
  }

  // Using the function
  const groupedData = groupData(averages);

  console.log({ groupedData });

  if (company === ECOMPANY.CUANTO && cuanto.length === 0) {
    throw new Error("לא נמצאה טבלת CUANTO");
  }

  const chaptersRaw = groupBy(
    (obj: any) => obj[CHAPTER_COLUMN],
    company === ECOMPANY.CUANTO ? cuanto : index
  );

  delete chaptersRaw["undefined"];

  console.log({ chaptersRaw });

  const chapters: IChapter[] = [];
  Object.entries(chaptersRaw).forEach(([chapterName, chapterEntries]) => {
    if (chapterName === "0") {
      return;
    }

    const sectionsRaw = groupBy(
      (obj: any) => obj[SECTION_COLUMN],
      chapterEntries
    );

    let sections: ISection[] = [];
    let dataSheet;
    let total = {};
    let totalOfTotals = 0;
    let averages = {};
    let bars = {};

    for (const sectionName in sectionsRaw) {
      const rawEntries = sectionsRaw[sectionName];

      rawEntries.forEach((e: any) => {
        // Handle average values
        if (e?.[ENTRIES_COLUMN]?.toString()?.trim()?.includes("ממוצע")) {
          const avg = e[ENTRIES_COLUMN];
          if (!averages[sectionName]) averages[sectionName] = {};
          if (avg.includes("ידני")) {
            averages[sectionName]["manual"] =
              e[ENTRIES_COLUMN].split("=")[1]?.trim();
          } else {
            averages[sectionName]["auto"] =
              e[ENTRIES_COLUMN].split("=")[1]?.trim();
          }
        }
        // Handle base price values
        if (e?.[ENTRIES_COLUMN]?.toString()?.trim()?.includes("מחיר בסיס")) {
          const bar = e[ENTRIES_COLUMN];
          if (!bars[sectionName]) bars[sectionName] = {};
          if (bar.includes("ידני")) {
            bars[sectionName]["manual"] =
              e[ENTRIES_COLUMN].split("=")[1]?.trim();
          } else {
            bars[sectionName]["auto"] = e[ENTRIES_COLUMN].split("=")[1]?.trim();
          }
        }
      });
      if (
        TOTAL_KEYWORDS.some((keyword) => sectionName.trim().includes(keyword))
      ) {
        if (!total[chapterName]) {
          total[chapterName] = {};
        }
        total[sectionName] = rawEntries[0]?.[ENTRIES_COLUMN];
        totalOfTotals += rawEntries[0]?.[ENTRIES_COLUMN];
        total[chapterName] = totalOfTotals;
        continue;
      }
      // if (sectionName === "0") {
      //   continue;
      // }

      if (isEntryADataTablePlaceholder(chapterName, rawEntries)) {
        console.log(chapterName);
        dataSheet = getDataSheetForTable(chapterName, workbook, rawEntries[0]);
      } else {
        sections.push({
          name: sectionName,
          entries: rawEntries.map((e: any) => e[ENTRIES_COLUMN]),
        });
      }
    }

    chapters.push({
      name: chapterName,
      dataSheet,
      sections,
      _sections: sections,
      total,
      totalOfTotals,
      averages,
      bars,
    });
  });

  const runLayoutEffect = () => {
    // Step 1: Handle chapter heights and page breaks
    let chapterHeight = 0;
    pdfRef?.current?.querySelectorAll(".chapter").forEach((el) => {
      if (el.classList.contains("notFullPage")) {
        const previousSibling = el.previousSibling;
        if (
          previousSibling instanceof HTMLElement &&
          !previousSibling.classList.contains("notFullPage")
        ) {
          el.classList.add("firstNotFullPage");
        }

        chapterHeight += el.clientHeight || 0;
      } else {
        chapterHeight = 0;
      }
      if (
        chapterHeight > CHAPTER_HEIGHT - OVERFLOW_BUFFER &&
        !el.classList.contains("firstNotFullPage")
      ) {
        el.querySelector(".chapter-image")?.remove();
        // const randomColor = getRandomColor();
        // el.closest("body")
        //   ?.querySelectorAll(`[data-name='${el.getAttribute("data-name")}']`)
        //   .forEach((el) => {
        //     (el as any).style.backgroundColor = randomColor;
        //   });
      }
    });

    // Step 2: Initialize group dictionaries
    let groupDict = {};
    const groupDictImages = {};

    // Step 3: Handle page breaks for groups
    pdfRef.current &&
      pdfRef.current.querySelectorAll("[data-group]").forEach((el) => {
        const group = el.getAttribute("data-group") || 0;
        if (!groupDict[group] && group !== "0") {
          // if no page break exists
          // if the previous element is not a page break and is not full page
          if (
            !el.previousElementSibling?.classList.contains("page-break") &&
            el.previousElementSibling?.classList.contains("notFullPage")
          ) {
            const newElement = document.createElement("div");
            newElement.style.breakAfter = "page";
            newElement.classList.add("page-break");
            el.parentElement?.insertBefore(newElement, el);
          }
          groupDict[group] = [];
          groupDictImages[group] = [];
          groupDict[group].push(el.getBoundingClientRect().height);
        }
      });

    // Step 4: Insert images for groups
    pdfRef.current &&
      pdfRef.current.querySelectorAll("[data-group]").forEach((el) => {
        const group = el.getAttribute("data-group") || 0;
        if (groupDict[group] && group !== "0") {
          if (groupImages[group]) {
            // create div element from html
            const chapterImage = el.querySelector(
              ".chapter-end-filler.chapter-image"
            );
            const insertedImage = el.querySelector(".inserted-image");
            if (chapterImage && !insertedImage) {
              // insert before footer
              chapterImage.outerHTML = `<div class="chapter-end-filler no-page-break chapter-image inserted-image">
                <img alt="" src="${
                  process.env.PUBLIC_URL + groupImages[group]
                }" />
                </div>`;
            }
          }
        }
      });

    // Step 4.5: Apply priority
    pdfRef.current?.querySelectorAll("[data-priority='1']").forEach((el) => {
      const group = el.getAttribute("data-group") || 0;
      // get the other chapter in this group with priority 0
      const otherChapter = pdfRef.current?.querySelector(
        `[data-group='${group}'][data-priority='0']`
      );
      if (otherChapter) {
        // take other chapter title outer HTML
        const otherChapterTitle = otherChapter.querySelector(
          ".chapter-title"
        ) as HTMLElement;

        // create HTML element from other chapter title outer HTML
        const otherChapterTitleEl = document.createElement("div");
        otherChapterTitleEl.innerHTML = otherChapterTitle.outerHTML;

        // take other chapter sections
        const otherChapterTable = otherChapter.querySelectorAll(
          ".table4"
        ) as unknown as HTMLCollectionOf<HTMLElement>;

        // create HTML element from other chapter sections
        const otherChapterTableEl = document.createElement("div");
        otherChapterTableEl.innerHTML = Array.from(otherChapterTable)
          .map((table) => table.outerHTML)
          .join("");

        // take the other chapters HTML and put it after the current chapter
        el.querySelector(".before-total-section")?.after(
          otherChapterTitleEl,
          otherChapterTableEl
        );
        // remove the other chapter from the original position
        otherChapter.remove();
      }
    });

    // Step 5: Calculate group heights and manage images
    pdfRef.current &&
      pdfRef.current.querySelectorAll("[data-group]").forEach((el) => {
        const group = el.getAttribute("data-group") || 0;
        if (groupDict[group] && group !== 0) {
          // console.warn(group);
          groupDict[group].push(el.getBoundingClientRect().height);
          if (Array.isArray(groupDictImages[group])) {
            groupDictImages?.[group]?.push(
              Array.from(el.querySelectorAll(".chapter-image")).reduce(
                (sum, el) => sum + el.getBoundingClientRect().height,
                0
              )
            );
          }
        } else {
          groupDict[group] = [el.getBoundingClientRect().height];
          groupDictImages[group] = Array.from(
            document.querySelectorAll(".chapter-image")
          ).reduce((sum, el) => sum + el.getBoundingClientRect().height, 0);
        }
        if (groupDict[group] && groupDict[group].length > 1 && group !== "0") {
          const sum = groupDict[group].reduce((a, b) => a + b, 0);
          console.warn(`Group ${group} takes ${sum} pixels`);
          console.warn(
            `Group ${group} images takes ${groupDictImages[group]} pixels`
          );
          // check if group takes more than a page
          if (CHAPTER_HEIGHT > sum) {
            console.log("======");
            console.log(
              `Group ${group} takes ${sum} pixels and images takes ${groupDictImages[group]} pixels`
            );
            console.log("======");

            console.warn(
              CHAPTER_HEIGHT <
                sum - groupDictImages[group].reduce((a, b) => a + b, 0)
            );
            // check if group minus images takes more than a page
            if (
              CHAPTER_HEIGHT <
              sum - groupDictImages[group].reduce((a, b) => a + b, 0)
            ) {
              // remove all images of the group
              el.querySelectorAll(".chapter-image").forEach((el) => {
                el.remove();
              });
              console.warn(`Removed all images of group ${group}`);
            }
            console.warn(`Group ${group} takes more than a page`);
            console.warn(
              `Group ${group} images takes ${groupDictImages[group]} pixels`
            );
          }
        }
      });

    // Step 6: Apply Cuanto table styles
    const applyCuantoTableStyle = (): void => {
      const newTableQuery: string =
        ".table-attache table th, .new-attache-tables table th";
      const newTableHeads = Array.from(
        pdfRef?.current?.querySelectorAll(
          newTableQuery
        ) as unknown as HTMLCollectionOf<HTMLElement>
      );
      newTableHeads.forEach(
        (h) => (h.style.background = ECUANTO_TABLE_COLORS.CUANTO_TH_COLOR)
      );

      // Table column colors
      const colsQuery: string =
        "table:not(.summary-table1, .summary-graph-table, .plan-table) tr:not(:first-child)";
      const tableColumns = Array.from(
        pdfRef?.current?.querySelectorAll(
          colsQuery
        ) as unknown as HTMLCollectionOf<HTMLElement>
      );
      tableColumns.forEach((c) => {
        if (c.childNodes.length > 3) {
          c.childNodes.forEach((td, i) => {
            const backgroundColor =
              i % 2 === 0 ? "rgba(241, 238, 227, 1)" : "rgba(249, 249, 247, 1)";
            //@ts-ignore
            td.style.background = backgroundColor;
          });
        } else {
          c.childNodes.forEach((td: ChildNode) => {
            let tdEl = td as HTMLElement;
            //@ts-ignore
            if (td.id.includes("-A"))
              tdEl.style.background = "rgba(241, 238, 227, 1)";
            //@ts-ignore
            else if (td.id.includes("-B"))
              tdEl.style.background = "rgba(249, 249, 247, 1)";
            //@ts-ignore
            else if (td.id.includes("-C"))
              tdEl.style.background = "rgba(228, 237, 249, 1)";
          });
        }
      });
    };

    // Step 6.5: Remove duplicate images
    const removeDuplicateImages = (): void => {
      // if there are two images with same url, remove the first one
      const images = document.querySelectorAll(".chapter-image img");
      images.forEach((image) => {
        const src = image.getAttribute("src");
        const duplicateImage = document.querySelectorAll(`[src="${src}"]`)?.[1];
        if (duplicateImage) {
          image.remove();
        }
      });
    };

    removeDuplicateImages();
    // Delay the execution of the group height check
    setTimeout(() => {
      document.querySelectorAll("[data-group]").forEach((el) => {
        if (el.getBoundingClientRect().height > CHAPTER_HEIGHT) {
          (el as HTMLElement).style.background = "transparent";
          el.querySelector(".chapter-image")?.remove();
        }
      });
    }, 1500); // Delay of 1500 milliseconds (1.5 seconds)

    // Step 7: Apply styles and set loading state
    setTimeout(() => {
      if (isCuanto) applyCuantoTableStyle();
      setIsLoading(false);
    }, 1200);
  };

  // Modify horizontal padding for specific td elements
  modifyTdElPaddingX("sjs-H1", "זיגוג", 12);
  modifyTdElPaddingX("sjs-D1", "כמות חלונות", 0);
  handleBasementComments();

  useRunEffect(runLayoutEffect, [pdfRef?.current, isCuanto], 2);

  return (
    <>
      <Stack
        width="100%"
        justifyContent="center"
        alignItems="center"
        my={4}
        className="hide-on-print"
      >
        <ButtonGroup
          size="large"
          aria-label="Large button group"
          sx={{
            direction: "ltr",
          }}
        >
          <Button
            onClick={() => {
              setViewType("print");
            }}
            variant={viewType === "print" ? "contained" : "outlined"}
          >
            תצוגת הדפסה
          </Button>
          <Button
            onClick={() => {
              setViewType("web");
            }}
            variant={viewType === "web" ? "contained" : "outlined"}
            sx={{ direction: "rtl" }}
            disabled
          >
            תצוגת Web
          </Button>
        </ButtonGroup>
        <Box mt={2}>
          <Button
            onClick={() => {
              setShowRegisterNewProjectModal(true);
            }}
          >
            שמירת גליון עבודה
          </Button>
        </Box>
      </Stack>
      {showRegisterNewProjectModal && (
        <RegisterNewProjectModal data={chapters} />
      )}
      {isLoading && (
        <div
          className="loader bg-black/90 flex w-full h-full absolute justify-center items-center z-50 left-0 top-0"
          style={{
            zIndex: 999999,
          }}
        >
          <span className="text-white text-5xl inline-block flex justify-center items-center flex-col">
            מעבד נתונים
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-white mt-4"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </span>
        </div>
      )}

      <Drawer
        open={viewType === "web"}
        anchor="right"
        variant="persistent"
        PaperProps={{
          style: {
            width: "250px",
            padding: "1rem",
            paddingTop: "0",
            overflow: "auto",
            backgroundColor: "white",
            color: "black",
          },
        }}
      >
        <h1>פרקים</h1>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {chapters.map((chapter) => {
            return (
              <>
                <ListItemButton
                  onClick={() => {
                    const el = pdfRef.current?.querySelector(
                      `[data-name="${chapter.name}"]`
                    );
                    if (el) {
                      el.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                >
                  <ListItemText
                    primary={chapter.name}
                    sx={{
                      textAlign: "right",
                    }}
                  />
                </ListItemButton>
              </>
            );
          })}
        </List>
      </Drawer>
      <div
        className={`pdf-preview ${viewType !== "print" ? "hidden" : ""}`}
        ref={pdfRef}
      >
        {chapters.map((chapter) => {
          console.log(chapter.name);
          console.log({ chapter });
          chapter.sections = mergeAndTrimChapter(chapter.sections) as any;
          const chapterProperties = getChapterProperties(chapter?.name);
          chapter.title = chapterProperties?.title || chapter.name;
          chapter.group = chapterProperties?.group || 0;
          chapter.breakSections = !!chapterProperties?.breakSections;
          chapter.noSections = !!chapterProperties?.noSections;
          chapter.hideInCuanto = !!chapterProperties?.hideInCuanto;
          chapter.priority = chapterProperties?.priority || 0;

          // if chapter have noSections === true, then we need to render out the sections part
          if (!chapter.noSections) {
            // remove sections with name === 0
            chapter.sections = chapter.sections.filter(
              (section) => section.name !== "0"
            );

            // if section array contains "בסיס"
            chapter.sections = chapter.sections.filter(
              (section) =>
                !section.entries.some((entry) => entry.includes("מחיר בסיס"))
            );

            // filter out chapters with sections containing the words "ממוצע"
            chapter.sections = chapter.sections.filter(
              (section) =>
                !section.entries.some(
                  (entry) =>
                    entry.includes("ממוצע פרויקט") ||
                    entry.includes("ממוצע (ידני)")
                )
            );
          }

          // console.table({ chapter });

          if (
            (chapter.sections.length === 0 && !chapter.noSections) ||
            (chapter.hideInCuanto && company === ECOMPANY.CUANTO)
          )
            return false;

          return chapterProperties?.component
            ? createElement(chapterProperties.component, chapter)
            : Chapter(chapter);
        })}
      </div>
      {/* <div
        className={`web-preview ${viewType !== "web" ? "hidden" : ""}`}
        ref={pdfRef}
      >
        {chapters.map((chapter) => {
          chapter.sections = mergeAndTrimChapter(chapter.sections) as any;
          const chapterProperties = getChapterProperties(chapter?.name);
          chapter.title = chapterProperties?.title || chapter.name;
          chapter.group = chapterProperties?.group || 0;
          chapter.breakSections = !!chapterProperties?.breakSections;
          chapter.noSections = !!chapterProperties?.noSections;
          chapter.hideInCuanto = !!chapterProperties?.hideInCuanto;
          if (
            (chapter.sections.length === 0 && !chapter.noSections) ||
            (chapter.hideInCuanto && company === ECOMPANY.CUANTO)
          )
            return false;
          return chapterProperties?.component
            ? createElement(chapterProperties.component, chapter)
            : Chapter(chapter);
        })}
      </div> */}
    </>
  );
};

export default PdfPreview;
