import * as XLSX from "xlsx";
import { groupBy } from "lodash/fp";
import { getChapterProperties, getEntriesByKey } from "../assets/utils";
import { PieChart } from "@rsuite/charts";
import { isEmpty } from "lodash";
import {
  ECOMPANY,
  ECUANTO_TABLE_COLORS,
  sectionToColorMap,
  sectionToColorMapCuanto,
  pieLabelCategories,
} from "../constants";
import { Context } from "../App";
import { useContext } from "react";
import { isMobile } from "react-device-detect";

const TABLE_CATEGORY = "קריטריון";

const TABLE_COLUMN_TWO = "סכום";
const TABLE_COLUMN_ONE = "סעיף";

const FinanceSummary = (financeSummaryChapter: any) => {
  const [company] = useContext(Context);
  const isCuanto = company === ECOMPANY.CUANTO;

  const index = XLSX.utils.sheet_to_json(financeSummaryChapter.dataSheet);
  const tables = groupBy((obj: any) => obj[TABLE_CATEGORY], index);
  const chapterProperties = getChapterProperties(financeSummaryChapter.name);

  if (isEmpty(index)) {
    return;
  }
  const createDetailsTable = (tableKey: string, alignLeft = false) => {
    return (
      <table className="summary-table1">
        <caption
          style={{
            backgroundColor: isCuanto
              ? ECUANTO_TABLE_COLORS.CUANTO_TH_COLOR
              : "",
          }}
        >
          {tableKey}
        </caption>
        <tbody>
          {tables[tableKey].map((detail) => {
            const columnOne = getEntriesByKey(detail, TABLE_COLUMN_ONE);
            let columnTwo = getEntriesByKey(detail, TABLE_COLUMN_TWO);
            if (!isNaN(columnTwo) && columnTwo == 0) return false;
            if (
              !isNaN(columnTwo) &&
              columnOne.trim() !== "מספר פרויקט" &&
              columnOne !== "הנחה"
            ) {
              columnTwo = new Intl.NumberFormat("he", {
                maximumFractionDigits: 0,
                style: "currency",
                currency: "ILS",
              }).format(columnTwo);
            }
            if (columnOne === "הנחה") {
              columnTwo = `${String(columnTwo * 100)}%`;
            }
            const isBlueRow: boolean =
              isCuanto && columnOne.trim() === 'מחיר למטר (כולל מע"מ)';
            const isPinkRow: boolean =
              isCuanto && columnOne.trim() === 'סה"כ כולל מע"מ';
            const assignClass = (): string =>
              isBlueRow ? "price-meter-td" : isPinkRow ? "tax-td" : "";

            return (
              <tr>
                <td
                  className={assignClass()}
                  style={{
                    backgroundColor: isCuanto
                      ? ECUANTO_TABLE_COLORS.CUANTO_SUMMARY_TD_COLOR
                      : "",
                  }}
                >
                  {columnOne}
                </td>
                <td
                  className={assignClass()}
                  style={{ textAlign: alignLeft ? "left" : "center" }}
                >
                  {columnTwo}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  const createGraphStyleTable = (tableKey: string) => {
    let maxSum = 0;
    tables[tableKey].forEach((tableRow) => {
      const currentSum = getEntriesByKey(tableRow, TABLE_COLUMN_TWO);
      if (currentSum > maxSum) {
        maxSum = currentSum;
      }
    });
    return (
      <table className="summary-graph-table w-full">
        <caption
          style={{
            backgroundColor: isCuanto
              ? ECUANTO_TABLE_COLORS.CUANTO_TH_COLOR
              : "",
          }}
        >
          {tableKey}
        </caption>
        <tbody>
          {tables[tableKey].map((detail: any) => {
            const columnOne = getEntriesByKey(detail, TABLE_COLUMN_ONE);
            let columnTwo = getEntriesByKey(detail, TABLE_COLUMN_TWO);
            let grapWidth: number = (columnTwo / maxSum) * 100;
            columnTwo = new Intl.NumberFormat("he", {
              maximumFractionDigits: 0,
              style: "currency",
              currency: "ILS",
            }).format(columnTwo);

            return (
              <tr>
                <td
                  style={{
                    backgroundColor: isCuanto
                      ? ECUANTO_TABLE_COLORS.CUANTO_SUMMARY_TD_COLOR
                      : "",
                  }}
                >
                  {columnOne}
                </td>
                <td>
                  <div
                    className="graph-progress"
                    style={{
                      width: grapWidth,
                      backgroundColor: isCuanto
                        ? sectionToColorMapCuanto[detail["סעיף"].trim()]
                        : sectionToColorMap[detail["סעיף"].trim()],
                    }}
                  ></div>
                </td>
                <td style={{ textAlign: "left" }}>{columnTwo}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const createPie = (tableKey: string) => {
    const checkIsSitePrep = (label: string): boolean =>
      isCuanto && label === "הכנת האתר לעבודות";
    let colorsForPie: string[] = [];
    const dataForPie = tables[tableKey].map((detail: any) => {
      const label = getEntriesByKey(detail, TABLE_COLUMN_ONE);
      let value = getEntriesByKey(detail, TABLE_COLUMN_TWO);
      colorsForPie.push(
        isCuanto
          ? sectionToColorMapCuanto[detail["סעיף"].trim()]
          : sectionToColorMap[detail["סעיף"].trim()]
      );
      return {
        value: value,
        label: label.trim(),
      };
    });

    const TOTAL = dataForPie
      .map((item) => item.value)
      .reduce((a, b) => a + b, 0);

    // @ts-ignore
    return (
      <>
        <PieChart
          // @ts-ignore
          color={colorsForPie}
          name=""
          data={dataForPie.map((data, i) => {
            return [
              `${((data.value / TOTAL) * 100).toFixed(1)}%`,
              Math.round(data.value) as any,
              i,
            ];
          })}
          className="pie-chart"
          legend={false}
          startAngle={50}
          labelLayout={{ width: 120, fontSize: 12 }}
          labelLine={{ length: 10, lineStyle: { width: 1 }, show: true }}
          radius={[40, 100]}
          label={{
            position: "outer",
            fontFamily: "Open Sans, sans-serif",
            color: "#43464c",
            fontSize: "9pt",
            fontWeight: 400,
          }}
        />
        <div className="flex w-full items-center" style={{ width: "340px" }}>
          <div className="flex w-full flex-row h-10 flex-wrap mr-12">
            {pieLabelCategories.map((c) => {
              return (
                <div className="flex flex-0 w-1/2 items-center gap-2">
                  <div
                    className="w-32 h-3"
                    style={{
                      backgroundColor: isCuanto
                        ? c.color
                        : sectionToColorMap[c.label],
                    }}
                  ></div>
                  <div className="w-full p-2 text-[8.5pt] font-normal text-[#43464c]">
                    {c.label}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="chapter financeSummary">
      <h2 className={"chapter-title"}>
        {" "}
        {chapterProperties && chapterProperties.icon && (
          <img src={process.env.PUBLIC_URL + chapterProperties.icon} alt="" />
        )}
        <span>
          {financeSummaryChapter?.title || financeSummaryChapter.name}
        </span>
      </h2>
      <div className={`finance-summary-tables ${isMobile ? "mobile" : ""}`}>
        <div className={`flex w-full gap-3 ${isMobile ? "flex-col" : ""}`}>
          <div
            className={`flex flex-1 w-1/2 flex-shrink-0 flex-col gap-3 ${
              isMobile ? "w-full" : ""
            }`}
          >
            {tables["תעודת זהות"] && (
              <div className="customer-id-table">
                {createDetailsTable("תעודת זהות")}
              </div>
            )}
            <div className="construction-table">
              {createGraphStyleTable("סעיף בניה")}
            </div>
          </div>
          <div
            className={`flex flex-1 w-1/2 flex-shrink-0 flex-col gap-3 ${
              isMobile ? "w-full" : ""
            }`}
          >
            <div className="cost-summary-table">
              {createDetailsTable("סיכום עלויות", true)}
            </div>
            <div className="graph-style-tables-section">
              <div className="project-steps-table">
                <>{createGraphStyleTable("שלבי הפרויקט")}</>
                <div className="pieChart">{createPie("שלבי הפרויקט")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceSummary;
