export interface ISection {
  name: string;
  entries: string[];
  cuanto?: string[];
  subEntries?: string[];
}

const SubEntriesList = ({
  subEntries,
  switchEntriesWithSubEntries,
}: {
  subEntries: string[];
  switchEntriesWithSubEntries: boolean;
}) => {
  return (
    <div className="section general-section">
      <ul
        className="section-text"
        style={{
          marginRight: switchEntriesWithSubEntries ? "-20px" : "0px",
        }}
      >
        {subEntries.map((subEntry, index) => (
          <li key={index}>{subEntry}</li>
        ))}
      </ul>
    </div>
  );
};

const EntriesList = ({
  entries,
  sectionName,
}: {
  entries: string[];
  sectionName: string;
}) => {
  return (
    <ul
      className={
        sectionName === "איטום מרתף- הערה"
          ? "general-comment section-text"
          : "section-text"
      }
    >
      {entries?.map((entry, index) => (
        <li key={index}>{entry}</li>
      ))}
    </ul>
  );
};

const Section = (section: ISection, switchEntriesWithSubEntries = false) => {
  const originalSectionSubEntries = section.subEntries;
  const sectionName = section.name;
  const isGeneralSection = sectionName.includes("הערות כלליות");
  const tempSubEntries = section.subEntries;
  let subEntries = !switchEntriesWithSubEntries
    ? section.entries
    : section.subEntries;
  section.entries =
    !switchEntriesWithSubEntries && tempSubEntries && section.subEntries
      ? tempSubEntries
      : section.entries;

  return (
    <div
      className={`no-page-break section ${
        isGeneralSection && "general-section"
      }`}
      data-section-name={section.name}
    >
      {!isGeneralSection && <h4 className="section-title">{section.name}</h4>}
      {switchEntriesWithSubEntries ? (
        <>
          {subEntries && originalSectionSubEntries && (
            <SubEntriesList
              subEntries={subEntries}
              switchEntriesWithSubEntries={switchEntriesWithSubEntries}
            />
          )}
          <EntriesList entries={section.entries} sectionName={section.name} />
        </>
      ) : (
        <>
          <EntriesList entries={section.entries} sectionName={section.name} />
          {subEntries && originalSectionSubEntries && (
            <SubEntriesList
              subEntries={subEntries}
              switchEntriesWithSubEntries={switchEntriesWithSubEntries}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Section;
