import * as XLSX from "xlsx";
import Section, { ISection } from "./Section";
import {
  getChapterProperties,
  getColumnCountFromCsv,
  transformSheetPrices,
} from "../assets/utils";
import { ECOMPANY } from "../constants";
import { useContext } from "react";
import { Context } from "../App";

export interface IChapter {
  title?: string;
  name: string;
  sections: ISection[];
  dataSheet?: XLSX.WorkSheet;
}

const WorkDetails = (chapter: IChapter) => {
  //const ref  = useRef<HTMLDivElement>(null);
  // @ts-ignore

  const [company] = useContext(Context);
  const chapterProperties = getChapterProperties(chapter?.name);
  const tableColumnCount =
    chapter.dataSheet &&
    getColumnCountFromCsv(XLSX.utils.sheet_to_csv(chapter.dataSheet, {}));

  const WORK_DETAILS_PRE_TITLE = "להלן מפרט עבודות הבניה:";

  return (
    <div
      key={chapter.name}
      data-name={chapter?.name?.trim()}
      className={`chapter ${
        chapterProperties?.notFullPage ? "notFullPage" : ""
      }`}
    >
      <img
        className="logo main"
        src={
          process.env.PUBLIC_URL +
          `/icons/${company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"}.svg`
        }
        alt=""
      />
      <h3
        className={`${company === ECOMPANY.ROSHENROZ && "grey-txt"} pre-title`}
      >
        {WORK_DETAILS_PRE_TITLE}
      </h3>
      <h2 className={"chapter-title"}>
        {" "}
        {chapterProperties && chapterProperties.icon && (
          <img alt="" src={process.env.PUBLIC_URL + chapterProperties.icon} />
        )}
        <span>
          {chapterProperties?.altName
            ? chapterProperties.altName
            : chapter.name}
        </span>
      </h2>
      {chapter.sections
        .filter((section) => section.name !== "כותרת על")
        .map((section) => Section(section))}
      {chapter.dataSheet && (
        <div
          className={`no-page-break ${
            (chapterProperties && chapterProperties.tableClass) || "table1"
          } table-col-count-${tableColumnCount}`}
          dangerouslySetInnerHTML={{
            __html: transformSheetPrices(
              XLSX.utils.sheet_to_html(chapter.dataSheet, { editable: true }),
              { removeDecimals: chapterProperties?.noDecimals }
            ),
          }}
        />
      )}
      <div className="chapter-end-filler no-page-break chapter-image">
        {chapterProperties && chapterProperties.img && (
          <img alt="" src={process.env.PUBLIC_URL + chapterProperties?.img} />
        )}
      </div>
      <div className="footer">
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/${
              company === ECOMPANY.CUANTO ? "footer_cuanto" : "footer"
            }.jpg`
          }
          alt=""
        />
      </div>
    </div>
  );
};

export default WorkDetails;
