import * as XLSX from "xlsx";
import { ISection } from "./Section";
import {
  formatComplexTableFromCSV,
  getChapterProperties,
} from "../assets/utils";
import { ECOMPANY } from "../constants";
import { useState, useEffect, useContext } from "react";
import LongTableItem from "./LongTableItem";
import { Context } from "../App";
import { IChapter } from "./Chapter";

const Electric = (chapter: IChapter) => {
  //const ref  = useRef<HTMLDivElement>(null);
  // @ts-ignore

  const [company] = useContext(Context);
  const chapterProperties = getChapterProperties(chapter?.name);

  // console.log("%cElectric", "color: red");
  // console.log({ chapterProperties });
  // console.log({ chapter });

  const [csvRaw, setCsvRaw] = useState<any>([]);

  const createElectricSubTable = (title: string): React.ReactElement => {
    const foundTable = csvRaw
      .filter((v) => v !== null)
      .find((v) => v?.title === title);
    return foundTable ? (
      <LongTableItem
        tableData={csvRaw
          .filter((v) => v !== null)
          .find((v) => v?.title === title)}
        title={title}
      />
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (chapter.dataSheet) {
      setCsvRaw(
        formatComplexTableFromCSV(XLSX.utils.sheet_to_csv(chapter.dataSheet))
      );
    }
  }, [chapter.dataSheet]);

  return (
    <div
      key={chapter.name}
      data-name={chapter?.name?.trim()}
      data-group={chapter.group}
      className={`chapter ${
        chapterProperties?.notFullPage ? "notFullPage" : ""
      }`}
    >
      <img
        className="logo main"
        src={
          process.env.PUBLIC_URL +
          `/icons/${company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"}.svg`
        }
        alt=""
      />
      <h2 className={"chapter-title"}>
        {" "}
        {chapterProperties && chapterProperties.icon && (
          <img alt="" src={process.env.PUBLIC_URL + chapterProperties.icon} />
        )}
        <span>
          {chapterProperties?.altName
            ? chapterProperties.altName
            : chapter.name}
        </span>
      </h2>
      <div
        style={{ display: "flex", flexDirection: "row" }}
        className="w-full gap-4"
      >
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="w-1/2"
        >
          <div className="table1 electric no-padding">
            {createElectricSubTable("שקעים")}
          </div>
          <div className="table1 electric no-padding">
            {createElectricSubTable("תקשורת - ללא חיווט")}
          </div>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="w-1/2"
        >
          <div className="table1 electric no-padding">
            {createElectricSubTable("מאור")}
          </div>
          <div className="table1 electric no-padding">
            {createElectricSubTable("שיפוצים")}
          </div>
          <div className="table1 electric no-padding">
            {createElectricSubTable("לוחות")}
          </div>
        </div>
      </div>
      <div className="chapter-end-filler no-page-break chapter-image">
        {chapterProperties && chapterProperties.img && (
          <img alt="" src={process.env.PUBLIC_URL + chapterProperties?.img} />
        )}
      </div>
      <div className="footer">
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/${
              company === ECOMPANY.CUANTO ? "footer_cuanto" : "footer"
            }.jpg`
          }
          alt=""
        />
      </div>
    </div>
  );
};

export default Electric;
