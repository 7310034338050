export enum ECOMPANY {
  ROSHENROZ = "rosh",
  CUANTO = "cuanto",
}

export const firebaseConfig = {
  apiKey: "AIzaSyBiJPIv502dyo2zbVZrhQfSp_QG_218vkk",
  authDomain: "excelestate-337d9.firebaseapp.com",
  projectId: "excelestate-337d9",
  storageBucket: "excelestate-337d9.appspot.com",
  messagingSenderId: "239184087768",
  appId: "1:239184087768:web:78fadb1ce38b2ededde5ad",
  measurementId: "G-BSZHYNH7JV",
};

export enum ECUANTO_TABLE_COLORS {
  CUANTO_SUMMARY_TD_COLOR = "#EEEADC",
  CUANTO_TH_COLOR = "#1D1F53",
  CUANTO_TD_COLOR1 = "#F4F4F2",
  CUANTO_TD_COLOR2 = "#F1EEE3",
  CUANTO_TD_COLOR3 = "#E4EDF9",
}

// Roshenroz only!
export const sectionToColorMap = {
  תכנון: "#B2DDE9",
  "הכנת האתר לעבודות": "#E4E9EB",
  "עבודות בניה": "#6993CD",
  "עבודות תשתית": "#6CA9B4",
  גמר: "#0094A0",
  "חומרי גמר": "#8FD1E3",
  פיתוח: "#D5907F",
  שונות: "#ECD3D9",
  איכלוס: "#B73433",
  "עבודות עפר": "#E4E9EB",
  "ארגון אתר ובטיחות": "#E4E9EB",
  "בטון יצוק באתר": "#6993CD",
  'חדרי ממ"ד': "#6993CD",
  "הריסה ופירוקים": "#E4E9EB",
  גבס: "#6993CD",
  איטום: "#6993CD",
  "גג רעפים": "#6993CD",
  אינסטלציה: "#6CA9B4",
  חשמל: "#6CA9B4",
  "מיזוג אוויר, גז ומערכות חימום": "#6CA9B4",
  "פלדה ומסגרות": "#0094A0",
  "טיח וגמר קירות": "#0094A0",
  "ריצוף וחיפוי": "#0094A0",
  "אבן ושיש": "#0094A0",
  אלומיניום: "#0094A0",
  דלתות: "#0094A0",
  "עבודות צביעה": "#0094A0",
  "אריחים וכלים סניטרים": "#8FD1E3",
  "חלונות וויטרינות": "#8FD1E3",
  "אריחים וכלים סניטריים": "#8FD1E3",
  "נגרות ומסגרות חוץ": "#D5907F",
  "גדרות וקירות תמך": "#D5907F",
  "חומות וקירות תמך": "#D5907F",
  "פיתוח נוף": "#D5907F",
  בריכה: "#D5907F",
  ניקיון: "#B73433",
  תאורה: "#B73433",
  הדברה: "#B73433",
  הובלה: "#B73433",
  התקנות: "#B73433",
  תקשורת: "#B73433",
  אזעקה: "#B73433",
  מצלמות: "#B73433",
  "נגרות פנים ומטבח": "#B73433",
};

// Cuanto only!
export const sectionToColorMapCuanto = {
  תכנון: "#64B6A2",
  "הכנת האתר לעבודות": "#6991CD",
  "עבודות בניה": "#CEC8A8",
  "עבודות תשתית": "#A10261",
  גמר: "#EC008C",
  "חומרי גמר": "#9FC9EB",
  פיתוח: "#607FAE",
  שונות: "#DBAE9F",
  איכלוס: "#1D1F53",
  "עבודות עפר": "#6993CD",
  "ארגון אתר ובטיחות": "#6993CD",
  "בטון יצוק באתר": "#CEC8A8",
  'חדרי ממ"ד': "#CEC8A8",
  "הריסה ופירוקים": "#6993CD",
  גבס: "#CEC8A8",
  איטום: "#CEC8A8",
  "גג רעפים": "#CEC8A8",
  אינסטלציה: "#A10261",
  חשמל: "#A10261",
  "מיזוג אוויר, גז ומערכות חימום": "#A10261",
  "פלדה ומסגרות": "#EC008C",
  "טיח וגמר קירות": "#EC008C",
  "ריצוף וחיפוי": "#EC008C",
  "אבן ושיש": "#EC008C",
  אלומיניום: "#EC008C",
  דלתות: "#EC008C",
  "עבודות צביעה": "#EC008C",
  "אריחים וכלים סניטרים": "#9FC9EB",
  "חלונות וויטרינות": "#9FC9EB",
  "אריחים וכלים סניטריים": "#9FC9EB",
  "נגרות ומסגרות חוץ": "#607FAE",
  "גדרות וקירות תמך": "#607FAE",
  "חומות וקירות תמך": "#607FAE",
  "פיתוח נוף": "#607FAE",
  בריכה: "#607FAE",
  ניקיון: "#1D1F53",
  תאורה: "#1D1F53",
  הדברה: "#1D1F53",
  הובלה: "#1D1F53",
  התקנות: "#1D1F53",
  תקשורת: "#1D1F53",
  אזעקה: "#1D1F53",
  מצלמות: "#1D1F53",
  "נגרות פנים ומטבח": "#1D1F53",
};

// Cuanto only!
export const pieLabelCategories: Array<{ label: string; color: string }> = [
  { label: "תכנון", color: "#64B6A2" },
  { label: "הכנת האתר לעבודות", color: "#6991CD" },
  { label: "עבודות בניה", color: "#CEC8A8" },
  { label: "עבודות תשתית", color: "#A10261" },
  { label: "גמר", color: "#EC008C" },
  { label: "חומרי גמר", color: "#9FC9EB" },
  { label: "פיתוח", color: "#607FAE" },
  { label: "שונות", color: "#DBAE9F" },
  { label: "איכלוס", color: "#1D1F53" },
];

export const totalSectionColors: {
  subChapters: string[];
  chapters: string[];
} = {
  subChapters: ["#617FAF", "#91A6C0", "#5FA995", "#6993CC", "#F08E73"],
  chapters: ["#EC008C", "#A10261", "#1D1F53"],
};
