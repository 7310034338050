import * as XLSX from "xlsx";
import Section, { ISection } from "./Section";
import {
  getChapterProperties,
  getColumnCountFromCsv,
  transformSheetPrices,
} from "../assets/utils";
import { ECOMPANY } from "../constants";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../App";
import { CHAPTER_HEIGHT } from "../PdfPreview";
import SkeletonSection from "./SkeletonSection";
import { IChapter } from "./Chapter";
import TotalSection from "./TotalSection";

const SkeletonWorks = (chapter: IChapter) => {
  //const ref  = useRef<HTMLDivElement>(null);
  // @ts-ignore

  const [company] = useContext(Context);
  const [isTwoPages, setIsTwoPages] = useState(false);

  const chapterProperties = getChapterProperties(chapter?.name);
  const tableColumnCount =
    chapter.dataSheet &&
    getColumnCountFromCsv(XLSX.utils.sheet_to_csv(chapter.dataSheet, {}));
  const chapterRef = useRef<HTMLDivElement>(null);
  const secondPageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const structuralWorks = chapterRef.current?.querySelector(
      "[data-section-name='עבודות ביסוס המבנה']"
    );
    const structuralWorksTable = chapterRef.current
      ?.querySelector("table")
      ?.closest(".no-page-break");
    if (structuralWorks && structuralWorksTable) {
      structuralWorks.appendChild(structuralWorksTable);
    }

    if (
      chapterRef.current &&
      chapterRef.current?.clientHeight > CHAPTER_HEIGHT
    ) {
      setIsTwoPages(true);
      // get last two sections
      const sections = chapterRef.current.querySelectorAll(".section");
      const lastSection = sections[sections.length - 1];
      const secondLastSection = sections[sections.length - 2];
      // append them to the second page
      secondPageRef.current?.appendChild(secondLastSection);
      secondPageRef.current?.appendChild(lastSection);
    }
  }, [chapterRef]);

  return (
    <>
      <div
        key={chapter.name}
        ref={chapterRef}
        data-group={chapter.group}
        data-name={chapter?.name?.trim()}
        className={`chapter ${
          chapterProperties?.notFullPage ? "notFullPage" : ""
        }`}
      >
        <img
          className="logo main"
          src={
            process.env.PUBLIC_URL +
            `/icons/${company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"}.svg`
          }
          alt=""
        />
        <h2 className={"chapter-title"}>
          {" "}
          {chapterProperties && chapterProperties.icon && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties.icon} />
          )}
          <span>
            {chapterProperties?.altName
              ? chapterProperties.altName
              : chapter.name}
          </span>
        </h2>
        {chapter.sections
          .filter((section) => section.name !== "כותרת על")
          .map((section) => SkeletonSection(section))}
        {chapter.dataSheet && (
          <div
            className={`no-page-break ${
              (chapterProperties && chapterProperties.tableClass) || "table1"
            } table-col-count-${tableColumnCount}`}
            dangerouslySetInnerHTML={{
              __html: transformSheetPrices(
                XLSX.utils.sheet_to_html(chapter.dataSheet, { editable: true }),
                { removeDecimals: chapterProperties?.noDecimals }
              ),
            }}
          />
        )}
        <div className="before-total-section hidden"></div>
        {chapter.total && chapter.totalOfTotals ? (
          <TotalSection
            name={chapter.name}
            total={chapter.total}
            totalOfTotals={chapter.totalOfTotals}
            averages={chapter.averages}
            bars={chapter.bars}
          />
        ) : null}
        <div className="chapter-end-filler no-page-break chapter-image">
          {chapterProperties && chapterProperties.img && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties?.img} />
          )}
        </div>
        <div className="footer">
          <img
            src={
              process.env.PUBLIC_URL +
              `/images/${
                company === ECOMPANY.CUANTO ? "footer_cuanto" : "footer"
              }.jpg`
            }
            alt=""
          />
        </div>
      </div>
      <div
        key={chapter.name + "_second_page"}
        data-name={chapter?.name?.trim() + "_second_page"}
        className={`chapter ${
          chapterProperties?.notFullPage ? "notFullPage" : ""
        }${isTwoPages ? "second_page" : ""}`}
        style={{
          display: isTwoPages ? "flex" : "none",
        }}
      >
        <img
          className="logo main"
          src={
            process.env.PUBLIC_URL +
            `/icons/${company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"}.svg`
          }
          alt=""
        />
        {/* <h2  className={"chapter-title"}>
          {" "}
          {chapterProperties && chapterProperties.icon && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties.icon} />
          )}
          <span>
            {chapterProperties?.altName
              ? chapterProperties.altName
              : chapter.name}
          </span>
        </h2> */}
        <div ref={secondPageRef}></div>
        {/* <div className="chapter-end-filler no-page-break chapter-image">
          {chapterProperties && chapterProperties.img && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties?.img} />
          )}
        </div> */}
        <div className="footer">
          <img
            src={
              process.env.PUBLIC_URL +
              `/images/${
                company === ECOMPANY.CUANTO ? "footer_cuanto" : "footer"
              }.jpg`
            }
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default SkeletonWorks;
