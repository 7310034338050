import React, { useContext } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Context } from "../App";
import "./TotalSection.scss";
import NormalDistChart, { normalDistColors } from "./NormalDistChart";
import { ECOMPANY, totalSectionColors } from "../constants";
import TotalSectionBar from "./TotalSectionBar";

const TotalSection = ({
  total,
  name,
  totalOfTotals,
  averages,
  bars,
}: {
  total: Record<string, number>;
  totalOfTotals: number;
  name: string;
  averages?: Record<string, { manual: number; auto: number }>;
  bars?: Record<string, { manual: number; auto: number }>;
}) => {
  const [company, setCompany, hideGraphs, setHideGraphs] = useContext(Context);
  const isCuanto: boolean = company === ECOMPANY.CUANTO;

  // format total function
  const sumAndFormatTotal = (total: number) => {
    const roundedTotal = Math.round(total);
    return roundedTotal.toLocaleString("he-IL", {
      style: "currency",
      currency: "ILS",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  if (hideGraphs) {
    return null;
  }

  // random number between 1 and 100

  return (
    <Stack direction="column" alignItems="flex-end" gap={1}>
      <Box
        my={2}
        justifyContent="center"
        alignItems="center"
        width="100%"
        display="flex"
      >
        <img
          className="w-full h-[1px]"
          src={process.env.PUBLIC_URL + "/utils/seperator.png"}
          alt=""
        />
      </Box>
      <Stack
        direction="row-reverse"
        justifyContent="space-between"
        width="100%"
        height="auto"
        position="relative"
      >
        <Stack flex={1} className="total__section__left">
          {Object.keys(total)
            .sort((a, b) => {
              if (a.includes(" (פרק)") && !b.includes(" (פרק)")) return 1;
              if (!a.includes(" (פרק)") && b.includes(" (פרק)")) return -1;
              return 0;
            })
            .map((key, i) => {
              if (company === ECOMPANY.ROSHENROZ) {
                return null;
              }
              if (key === name) {
                return null;
              }
              const isChapter = key.includes(" (פרק)");
              const chapterIndex = isChapter
                ? Object.keys(total)
                    .filter((k) => k.includes(" (פרק)"))
                    .indexOf(key)
                : null;

              return (
                <Stack
                  key={key}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <span
                    className={`total__total_text__alt ${
                      isChapter ? "colored" : ""
                    }`}
                    style={{
                      fontWeight: isCuanto ? "900" : "",
                      color: isChapter
                        ? totalSectionColors.chapters[chapterIndex || 0]
                        : totalSectionColors.subChapters[i - 1 || 0],
                    }}
                  >
                    {`${isCuanto && isChapter ? 'סה"כ ' : ""}` +
                      key
                        .replace('סה"כ', "")
                        .replace("סה״כ", "")
                        .replace("סהכ", "")
                        .replace(" (תת פרק)", "")
                        .replace(" (פרק)", "")}
                  </span>
                  <div
                    className={`total__total_price__alt ${
                      isChapter ? "colored" : ""
                    }`}
                    style={{
                      color: isChapter
                        ? totalSectionColors.chapters[chapterIndex || 0]
                        : totalSectionColors.subChapters[i - 1 || 0],
                    }}
                  >
                    {sumAndFormatTotal(total[key])}
                  </div>
                </Stack>
              );
            })}

          {company === ECOMPANY.ROSHENROZ && (
            <Stack
              key="total_of_totals"
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <span
                className={`total__total_text__alt colored`}
                style={{
                  color: normalDistColors[0],
                }}
              >
                סה״כ {name}
              </span>
              <div
                className={`total__total_price__alt colored`}
                style={{
                  color: normalDistColors[0],
                }}
              >
                {sumAndFormatTotal(total[name])}
              </div>
            </Stack>
          )}
        </Stack>
        <Stack
          flex={1}
          pr={6}
          minHeight={
            isCuanto && Object.keys(averages || {}).length > 0
              ? "140px"
              : "auto"
          }
          sx={{
            opacity: isCuanto ? 1 : 0,
          }}
        >
          {bars &&
            Object.keys(bars).length > 0 &&
            Object.values(bars)[0].manual > 0 && (
              <Box
                marginRight="3em"
                sx={{
                  opacity: 0,
                }}
              >
                <Typography
                  sx={{
                    color: "#607FAE",
                    textAlign: "right",
                    fontFamily: '"Open Sans"',
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                    transform: "translateX(38px)",
                    marginTop: "-2px",
                  }}
                  pb="18px"
                >
                  רמת גימור / מורכבות העבודה
                </Typography>
                <div
                  style={{
                    height: isCuanto ? "76px" : "auto",
                    borderRight: "2px solid #607FAE",
                    paddingRight: "4px",
                    display: "flex",
                    alignItems: "flex-end",
                    position: "relative",
                  }}
                >
                  {Object.entries(bars).map(([key, value], index) => {
                    return (
                      <TotalSectionBar
                        key={key}
                        index={index}
                        height={(value.manual / value.auto) * 100}
                      />
                    );
                  })}
                  <div
                    style={{
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      right: "-48px",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      fontSize: "11px",
                      color: "rgba(96, 127, 174, 1)",
                      fontWeight: "bold",
                    }}
                  >
                    <span>גבוהה</span>
                    <span>סטנדרט</span>
                  </div>
                </div>
              </Box>
            )}
        </Stack>
        <Stack flex={1}>
          {isCuanto && Object.keys(averages || {}).length > 0 && (
            <Box mt="-128px" position="absolute" top="0" right="2em">
              <NormalDistChart
                points={Object.values(averages || {})
                  .map((avg) => {
                    const res = 100 - Math.round((avg.auto / avg.manual) * 100);
                    if (res > 80) return 80;
                    if (res < 20) return 20;
                    return res;
                  })
                  .filter((point) => point !== 0 && !isNaN(point))}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TotalSection;
